/* eslint-disable no-undef */
import {
  ACCESS_TOKEN_NAME,
  REFRESH_TOKEN_NAME,
  TOKEN_ID,
} from "@/constants/auth";
import { isClient, isServer } from "@/utils/common.util";
import { getItem, setItem } from "@/utils/cookies.util";
import { checkAccessToken } from "@/utils/jwt.utils";
import { ApolloProvider } from "@apollo/react-hooks";
import { message } from "antd";
import { ApolloClient, ApolloLink } from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import fetch from "isomorphic-unfetch";
import withApollo from "next-with-apollo";
import Router from "next/router";
import React from "react";

const apiPath =
  process.env.NODE_ENV === "production"
    ? "https://api.medicgo.org/api/v1/"
    : "http://127.0.0.1:3000/graphql";

function createLink(ctx?: any) {
  const BasicLink = new HttpLink({
    uri: apiPath,
    fetch,
  });

  const AuthLink = setContext((_, { headers }) => {
    const token = isServer
      ? ctx?.accessToken || getItem(ACCESS_TOKEN_NAME, ctx)
      : getItem(ACCESS_TOKEN_NAME);
    const authorizationHeader = token
      ? { authorization: `Bearer ${token}` }
      : {};
    return {
      headers: {
        ...headers,
        ...authorizationHeader,
      },
    };
  });

  const refreshTokenMutation = JSON.stringify({
    operationName: null,
    variables: {
      data: {
        refreshToken: getItem(REFRESH_TOKEN_NAME, ctx),
        accessToken: getItem(ACCESS_TOKEN_NAME, ctx),
        id: getItem(TOKEN_ID, ctx),
      },
    },
    query: `mutation ($data: RefreshDto!) {
      refreshAccessToken(refresh: $data) {
        accessToken
        refreshToken
        id
      }
    }`,
  });
  const Refreshlink = new TokenRefreshLink({
    accessTokenField: ACCESS_TOKEN_NAME,
    isTokenValidOrUndefined: () => !checkAccessToken(ctx),
    fetchAccessToken: async () => {
      const resp = await fetch(apiPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: refreshTokenMutation,
      });
      return resp.json();
    },
    handleFetch: () => {},
    handleResponse: () => (response: { data: any }) => {
      const { refreshAccessToken } = response?.data;
      if (isServer) {
        ctx.accessToken = refreshAccessToken?.accessToken;
        ctx.refreshToken = refreshAccessToken?.refreshToken;
        ctx.userId = refreshAccessToken?.id;
      }
      setItem(ACCESS_TOKEN_NAME, refreshAccessToken?.accessToken, ctx);
      setItem(REFRESH_TOKEN_NAME, refreshAccessToken?.refreshToken, ctx);
      setItem(TOKEN_ID, refreshAccessToken?.id, ctx);
      return { accessToken: refreshAccessToken?.accessToken };
    },
    handleError: (e) => {
      if (isClient) Router.push("/auth/login");
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (isClient) {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          message.error(error.message);
          console.log(error.message);
          if (error.message === "Unauthorized") {
            Router.push("/auth/login");
          }
        });
      }
      if (networkError) {
        message.error("Network Error");
      }
    }
    if (isServer) {
      if (networkError) {
        console.log(networkError);
      }
      if (graphQLErrors) {
        console.log(graphQLErrors);
        console.log(graphQLErrors?.map((err) => err?.message));
      }
    }
  });

  return ApolloLink.from([Refreshlink as any, AuthLink, errorLink, BasicLink]);
}

export default withApollo(
  ({ ctx, initialState }) => {
    return new ApolloClient({
      cache: new InMemoryCache().restore(initialState || {}),
      link: createLink(ctx),
    });
  },
  {
    render: ({ Page, props }) => {
      return (
        <ApolloProvider client={props.apollo}>
          <Page {...props} />
        </ApolloProvider>
      );
    },
  }
);
