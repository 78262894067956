import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from "@/constants/auth";
import {
  IAccessTokenPayload,
  IBasicTokenPayload,
} from "@/interfaces/auth.interface";
import jwt from "jsonwebtoken";
import { NextPageContext } from "next";
import { getItem } from "./cookies.util";

export const decodeToken = (s: string) =>
  jwt.decode(s) as IBasicTokenPayload | IAccessTokenPayload;

export const checkAccessToken = (ctx?: NextPageContext): boolean => {
  const accessToken = getItem(ACCESS_TOKEN_NAME, ctx);
  const refreshToken = getItem(REFRESH_TOKEN_NAME, ctx);
  if (accessToken && refreshToken) {
    const decodeAccessJWT = decodeToken(accessToken);
    const decodeRefreshJWT = decodeToken(refreshToken);
    const now = new Date().getTime();
    const accessExp = decodeAccessJWT.exp * 1000;
    const refreshExp = decodeRefreshJWT.exp * 1000;

    if (refreshExp < now) {
      return false;
    }
    if (accessExp < now) {
      return true;
    }
  }
  return false;
};
