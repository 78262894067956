import { ACCESS_TOKEN_NAME } from "@/constants/auth";
import { GQL_CURRENT_USER } from "@/graphql/auth.graphql";
import withApollo from "@/graphql/withApollo";
import { Client } from "@/interfaces/query.interface";
import { currentUserState } from "@/stores/common";
import "@/styles/global.less";
import "@/styles/tailwind.base.css";
import { isClient } from "@/utils/common.util";
import { getItem, removeToken } from "@/utils/cookies.util";
import i18n, { antdI18Map, muiI18Map } from "@/utils/i18n.util";
import { LoadingOutlined } from "@ant-design/icons";
import { getDataFromTree } from "@apollo/react-ssr";
import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { RewriteFrames } from "@sentry/integrations";
import * as Sentry from "@sentry/node";
import { ConfigProvider, Spin } from "antd";
import App from "next/app";
import getConfig from "next/config";
import Head from "next/head";
import { useEffect, useMemo, useState } from "react";
import { RecoilRoot } from "recoil";
import "./app.css";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.SENTRY_RELEASE,
  });
}

const { appWithTranslation } = i18n;

const instance = createInstance({
  urlBase: "https://static.medicgo.org",
  siteId: 8,
  // @ts-ignore
  enableLinkTracking: false,
  trackerUrl: "https://static.medicgo.org/js/",
  srcUrl: "https://static.medicgo.org/js/",
});

if (isClient) {
  const antIcon = <LoadingOutlined style={{ fontSize: "2rem" }} spin />;
  Spin.setDefaultIndicator(antIcon);
}

function MyApp(appProps) {
  const {
    Component,
    pageProps,
    currentUser = {},
    language: _language,
    err,
  } = appProps;

  const [language, setLanguage] = useState<string>(_language);

  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    setLanguage(i18n.i18n.language);
  }, [i18n.i18n.language]);

  const theme = useMemo(
    () =>
      createMuiTheme(
        {
          typography: {
            fontSize: 14,
          },
          palette: {
            primary: {
              main: "#e33371",
            },
            secondary: {
              main: "#ff6f00",
              light: "#ff903b",
            },
          },
        },
        muiI18Map[language]
      ),
    [language]
  );

  return (
    <>
      <Head>
        <title>MedicGo - Cut through the clutter</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <ConfigProvider locale={antdI18Map[language]}>
        <ThemeProvider theme={theme}>
          <MatomoProvider value={instance}>
            <RecoilRoot
              initializeState={({ set }) => {
                const { __typename, ...info } = currentUser;
                set(currentUserState, info);
              }}
            >
              <Component {...pageProps} err={err} />
            </RecoilRoot>
          </MatomoProvider>
        </ThemeProvider>
      </ConfigProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext: any) => {
  const appProps = await App.getInitialProps(appContext);
  const { ctx } = appContext;
  let data: any = {};
  // @ts-ignore
  const client: Client = ctx.apolloClient;
  if (getItem(ACCESS_TOKEN_NAME, ctx)) {
    try {
      const { data: rData } = await client.query({
        query: GQL_CURRENT_USER,
      });
      data = rData;
    } catch (error) {
      removeToken(ctx);
    }
  }
  return {
    ...appProps,
    ...data,
    language: i18n.i18n.language,
    namespacesRequired: ["common", "menu", "auth"],
  };
};

export default appWithTranslation(withApollo(MyApp, { getDataFromTree }));
