import { gql } from "apollo-boost";

export const GQL_LOGIN = gql`
  mutation($loginDto: LoginDto!) {
    login(loginDto: $loginDto) {
      accessToken
      refreshToken
      id
    }
  }
`;

export const GQL_REGISTER = gql`
  mutation($register: RegisterDto!) {
    register(register: $register) {
      accessToken
      refreshToken
      id
    }
  }
`;

export const GQL_CURRENT_USER = gql`
  {
    currentUser {
      username
      email
      avatarUrl
      roles
      status
      id
    }
  }
`;

export const GQL_REFRESH_TOKEN = gql`
  mutation($data: RefreshDto!) {
    refreshAccessToken(refresh: $data) {
      accessToken
      refreshToken
      id
    }
  }
`;

export const GQL_REQUEST_RESET_PASSWORD_EMAIL = gql`
  mutation($email: String!, $response: String!) {
    requestResetPasswordEmail(email: $email, response: $response)
  }
`;

export const GQL_RESET_PASSWORD = gql`
  mutation($reset: ResetPasswordDto!) {
    resetPassword(resetPasswordDto: $reset)
  }
`;
